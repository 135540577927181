import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Google Material Design App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/google-material-design-mobile-email-app/"
    metaDescription="Design your own mobile email app in the style of Google Material Design with our custom-built email app template. Try for free today."
    description="
    h2:An email app design template that uses Google Material Design
    <br/>
    One of Uizard's most popular templates for app creation is this mobile email app prototype that embodies Material Design, an open-source design system created by Google to help teams create high-quality and functional digital experiences for mobile.
    <br/>
    h3:An email template for mobile app design and iteration
    <br/>
    If you want to design an <a:https://uizard.io/mockups/>app UI mockup</a> that uses Material Design as pioneered by Google, then Uizard is here to answer your prayers. The Google Material Design theme is built directly into this Uizard template to empower you to create your own email app design.
    <br/>
    h3:A functional and customizable mobile email app template embodying UI best practices
    <br/>
    As one of our most well-liked <a:https://uizard.io/templates/mobile-app-templates/>mobile app templates</a>, this email app prototype is an example of Google Material Design in action. It embodies UI best practices, with fully customizable content and architecture, making it very easy for you to tailor your app design to specific use cases.
    "
    pages={[
      "An inbox",
      "A contact page",
      "A contact details page",
      "A page for writing the emails",
    ]}
    projectCode="nYgE8XpepJSvgvnGxPrb"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Google Material Design Email App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Google Material Design Email App: contact details page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Google Material Design Email App: input page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Google Material Design Email App: new email page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Google Material Design Email App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/google-material-design-email-app/GMDEA-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/google-material-design-email-app/GMDEA-contact-details.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/google-material-design-email-app/GMDEA-inbox.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/google-material-design-email-app/GMDEA-new-email.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/google-material-design-email-app/GMDEA-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
